/* Style for the outer container */
.ais-RefinementList {
  justify-content: center;
}

/* Style for the list */
.ais-RefinementList-list {
  list-style-type: none;
  padding: 0;
}

/* Style for each list item */
.ais-RefinementList-item {
  margin-bottom: 5px;
}

/* Style for label */
.ais-RefinementList-label {
  cursor: pointer;
  display: flex;
  gap: 5px;
}

/* Style for checkbox */
.ais-RefinementList-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #595b5e;
  border-radius: 4px;
  background-color: #27292d;
  cursor: pointer;
  flex-shrink: 0;
  margin-top: "10px";
}

.ais-RefinementList-checkbox:checked {
  background-color: rgb(248, 103, 67);
  border-color: rgb(248, 103, 67);
  width: 16px;
  height: 16px;
}

/* Style for checked state - inner dot */
.ais-RefinementList-checkbox:checked::before {
  content: "\2714";
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 18px;
  color: #fff;
  font-size: 14px;
}

/* Style for hover state */
/* .ais-RefinementList-checkbox:hover {
    border-color: rgb(248, 103, 67);
  } */

/* Style for label text */
.ais-RefinementList-labelText {
  font-size: 16px;
  display: inline-flex;
  flex-direction: row;
  cursor: pointer;
  font-family: var(--zds-typography-base, Inter, Helvetica, arial, sans-serif);
}

/* Style for count */
.ais-RefinementList-count {
  /* margin-left: 5px;
    font-weight: bold;
    color: gray; */
  display: none;
}

.ais-ClearRefinements-button {
  cursor: pointer;
  color: rgb(248, 103, 67);
  border: none;
  font-size: 16px;
  background-color: transparent;
}

.ais-ClearRefinements {
  text-align: left;
}
.dialog-content::-webkit-scrollbar {
  width: 8px;
}

.dialog-content::-webkit-scrollbar-thumb {
  background-color: #494949;
  border-radius: 5px;
}

.dialog-content::-webkit-scrollbar-track {
  background-color: transparent;
}